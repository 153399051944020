// noinspection TypeScriptValidateTypes

import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Camera, CameraPluginPermissions, CameraResultType, CameraSource} from "@capacitor/camera";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {Device} from "@capacitor/device";
import {StatusBar} from "@capacitor/status-bar";
import {Capacitor} from "@capacitor/core";
import {TokenService} from "./services/auth/token.service";
import {ApiService} from "./services/api/api.service";
import {environment} from "../environments/environment";
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {PartnerDetailsService} from "./services/partner-details.service";
import {NotificationsService} from "./services/notifications/notifications.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'bookmymot-partners-dash';
  public images:Array<string|undefined> = [];
  public details?:Observable<any>;
  public identifier:string = '';
  private permissions: CameraPluginPermissions | undefined;
  constructor(
    private firebase:AngularFireDatabase,
    private token:TokenService,
    private api:ApiService,
    private router:Router,

    private partnerDetailsService:PartnerDetailsService,

    private notificationService:NotificationsService
  ) {}

  ngOnInit() {
    this.api.get(`${environment.apiURL}${environment.endpoints.me}`).subscribe(data=>{
        this.partnerDetailsService.setDetails(data)
      this.notificationService.setUser(data.id)
    }, (error)=>this.token.forgetToken().then(()=>{
      this.router.navigateByUrl('/login')
    }))

    if(Capacitor.getPlatform()!='web'){
      StatusBar.hide();
    }
  }
}
