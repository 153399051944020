<div class="w-full h-[100vh]  flex justify-center items-center">
    <div class="w-[50%] min-h-[50%] rounded-lg bg-primary relative" [formGroup]="loginForm">
      <div class=" screen-1 w-full h-full p-10">
        <p class="w-full text-[30px] text-white  text-center">Login</p>
        <div class="fields w-[80%] flex flex-col justify-center items-center mx-auto mt-10">
          <div class="form-control flex flex-col w-full">
            <label class="my-2 text-white text-[20px]">User</label>
            <input type="text" class="text-[30px] w-full rounded-lg border-secondary border-4 p-5" placeholder="user@mail.com" formControlName="user">
          </div>

          <div class="form-control flex flex-col w-full my-4">
            <label class="my-2 text-white text-[20px]">Password</label>
            <input [type]="showPassword?'text':'password'" class="text-[30px] w-full rounded-lg border-secondary border-4 p-5" placeholder="Password" formControlName="password"  (keyup.enter)="attemptAuth()">
          </div>

          <p class="text-start p-2 text-white w-full cursor-pointer" (click)="showPassword=  !showPassword">{{showPassword?"Hide password":"Show Password"}}</p>

          <button class="w-[400px] border-white border-4 bg-secondary text-white text-[30px] font-bold mt-10 p-5 rounded-lg"
                  (click)="attemptAuth()">
            Submit
          </button>
        </div>
      </div>
    </div>
</div>
