import {environment} from "../../environments/environment";

const base = environment.apiURL

/**
 * Method = POST
 */
export const authenticate = ()=>{
  return `${base}login`
}

export const getHistoryBooking = ():string=>{
  return `${base}history/booking`
}

export const cancelBooking = ()=>{
  return `${base}booking/cancel`
}

export const completeBooking = ()=> {
  return `${base}booking/complete`
}

export const updateBookingStatus = ()=>{
  return `${base}booking/update/state`
}

export const updateJob = ()=>{
  return `${base}jobs/update`
}

export const updatePartnerDetails = ()=>{
  return `${base}details/update`
}

export const updateServicePrice = ()=>{
  return `${base}services/update-base-price`
}
