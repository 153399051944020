import { Injectable } from '@angular/core';
import {Preferences} from "@capacitor/preferences";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenKey:string = 'book_my_mot'
  constructor() { }

  public hasToken(): Promise<boolean> {
    return this.getTokenFromStorage().then(
      (data:any) => {
        return !!data;
      }
    ).catch((error) => {
      console.error("Failed to get token from storage: ", error);
      return false;
    });
  }

  public async getTokenFromStorage(){
    const {value} = await Preferences.get({key:this.tokenKey})
    return value
  }

  public setToken(token:string):any {
   return  Preferences.set({key:this.tokenKey, value:token})
  }

  public forgetToken() {
    return Preferences.remove({key:this.tokenKey})
  }
}
