import {
  authenticate,
  cancelBooking,
  completeBooking,
  getHistoryBooking,
  updateBookingStatus,
  updateJob, updatePartnerDetails, updateServicePrice
} from "./routes";

export class RoutesManager{
  public static auth:string = authenticate()
  public static cancelBooking:string = cancelBooking()
  public static updateBookingState:string = updateBookingStatus()
  public static updateJob:string = updateJob()
  public static completeBooking:string = completeBooking()
  public static getHistoryBooking:string = getHistoryBooking()
  public static updatePartnerDetails:string = updatePartnerDetails()
  public static updateServicePrice:string = updateServicePrice()
}
