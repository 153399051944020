import { NgModule } from '@angular/core';
import {ExtraOptions, Route, RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {PartnerAuthGuard} from "./guards/auth/partner-auth.guard";

const routes: Routes = [
  {
    path:'',
    pathMatch:'full',
    redirectTo:'dashboard'
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'dashboard',
    canActivate:[PartnerAuthGuard],
    loadChildren:()=>import('./dashboard/dashboard.module').then(m=>m.DashboardModule)
  },
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
