import { Injectable } from '@angular/core';
import {map, Observable, take, tap} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class FireBaseService {
  constructor(private fireStore:AngularFirestore) {}


  public  getVehicle(bookingId:any):Observable<any>{
      return this.fireStore.collection(`bookings/`).doc(`booking-${bookingId}`).valueChanges().pipe(map(
        (data:any)=>data.vehicle
      ))
  }

  public  getNotifications (partnerId:number):Observable<any> {
    return this.fireStore.collection(`partners/`).doc(`partner-${partnerId}`).collection('notifications').valueChanges()
  }

  public readNotification(partnerId:number|null|undefined) :void {
    if(!partnerId) return
    const notificationsCollection = this.fireStore.collection(`partners/`).doc(`partner-${partnerId}`).collection('notifications');
    notificationsCollection.get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        notificationsCollection.doc(doc.id).update({status: 'read'});
      });
    });
  }

  public clearNotifications(partnerId:undefined|null|number):void {
    if(!partnerId) return
    const notificationsCollection = this.fireStore.collection(`partners/`).doc(`partner-${partnerId}`).collection('notifications');
    notificationsCollection.get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        notificationsCollection.doc(doc.id).delete();
      });
    });
  }

}
