import {CanActivateFn, Router} from '@angular/router';
import {Injectable} from "@angular/core";
import {AuthenticationService} from "../../services/auth/authentication.service";

@Injectable()
export class PartnerAuthGuard {

  constructor(private auth:AuthenticationService, private router:Router) { }
  canActivate:CanActivateFn = async ():Promise<boolean> => {
    try {
      let result =  await this.auth.isAuth();
      return result
    } catch (err) {
      console.error(err);
      this.router.navigateByUrl('/login')
      return false; // you might want to redirect to login page here or handle the error accordingly.
    }
  }

}
