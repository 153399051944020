import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PartnerDetailsService {

  public partnerDetails = new BehaviorSubject<any>({})

  public partnerDetails$:Observable<any> = this.partnerDetails.asObservable()
  constructor() { }

  public setDetails(value:any) {
    this.partnerDetails.next(value)
  }

}
