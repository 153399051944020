import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders
} from '@angular/common/http';

import {from, Observable, switchMap, tap} from 'rxjs';
import {TokenService} from "../services/auth/token.service";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class FillTokenInterceptor implements HttpInterceptor {

  constructor(private token:TokenService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.token.getTokenFromStorage()).pipe(
      switchMap(token => {
        if (token) {
          let newHeaders = new HttpHeaders().set("auth-token", token);

          if (!(req.body instanceof FormData)){
            newHeaders = newHeaders.set("Content-Type", "application/json");
          }
          req = req.clone({
            headers: newHeaders
          });
        }
        return next.handle(req);
      })
    );
  }
}
