import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../services/auth/authentication.service";
import {ApiService} from "../services/api/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RoutesManager} from "../Api/RoutesManager";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import Swal from "sweetalert2";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public loginForm:FormGroup = this.fb.group({
    user:['', Validators.required],
    password:['', Validators.required]
  })

  public showPassword = false;

  constructor(
    private router: Router,
    private auth:AuthenticationService,
    private api:ApiService,
    private fb:FormBuilder,
    private fireAuth:AngularFireAuth,
  ) {}

  public attemptAuth() {
    if(this.loginForm.valid) {
      this.api.post(RoutesManager.auth, this.loginForm.value).subscribe((result:{token:string, status:number})=>{
        console.log(result)
        if(result.status == 200) {
          this.fireAuth.signInWithEmailAndPassword(this.loginForm.get('user')?.value, this.loginForm.get('password')?.value).then(()=>{
            this.auth.authenticate(result.token).then(()=>{
              this.router.navigateByUrl('/dashboard')
            })
          })
        }
      }, (e:any)=>{
       Swal.fire({icon:"warning", title:"Login Error!", text:"We are not able to find your details." })
      })
    }
  }

  // public sendUser():void{
  //   if(this.loginForm.get('user')?.valid){
  //     this.api.post(`${environment.apiURL}invoke-auth`, {telephone:this.loginForm.get('user')?.value}).subscribe((results:any)=>{
  //       if(results.status == 200){
  //
  //       }
  //     });
  //   }
  // }
  //
  // public sendCode():void {
  //   if(this.loginForm.get('code')?.valid){
  //     this.api.post(`${environment.apiURL}complete-auth`, {
  //       telephone:this.loginForm.get('user')?.value,
  //       sms_code:this.loginForm.get('code')?.value
  //     }).subscribe((result:any)=>{
  //       if(result.status == 200){
  //
  //       }
  //     });
  //   }
  // }
}
