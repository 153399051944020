import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {Capacitor} from "@capacitor/core";
import {StatusBar} from "@capacitor/status-bar";
import {AuthenticationService} from "../auth/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private httpHeaders = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  constructor(private http:HttpClient, private auth:AuthenticationService) {

    if(Capacitor.getPlatform()!='web'){
      StatusBar.hide();
    }
  }

  /**
   * Get request
   * @param url
   */
  public get(url:string): Observable<any>{
    return this.http.get(url, this.httpHeaders).pipe(
      tap({
        error: error => {
          if (error.status === 500) {
            console.log(error)
            if(error.error.message && error.error.message=='Partner not verified') {
              this.auth.logOut()
            }
            // Handle 500
          } else if (error.status === 400) {
            // Handle 400
          } else if (error.status === 401) {
            // Handle 401
          }
        }}));
  }

  /**
   * Post request
   * @param url
   * @param data
   */
  public post(url:string, data:any=[]):Observable<any>{
    return this.http.post(url, data, this.httpHeaders).pipe(
      tap({
        error: error => {

          if (error.status === 500) {
            console.log(error)
            if(error.error.message && error.error.message=='Partner not verified') {
              this.auth.logOut()
            }
          } else if (error.status === 400) {
            // Handle 400
          } else if (error.status === 401) {
            // Handle 401
          }
        }}));
  }

}
