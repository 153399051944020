import { Injectable } from '@angular/core';
import {TokenService} from "./token.service";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private token:TokenService, private router:Router) { }


 public isAuth = async ()=> {
    try {
      return await this.token.hasToken();
    }catch (error){
      console.log(error);
      return false
    }
  }


  public authenticate = async (token :string):Promise<boolean>=>{
    try {
        return await this.token.setToken(token)
    }catch(err){
      console.log(err)
      return false;
    }
  }

  public logOut = async ()=>{
      await this.token.forgetToken().then(()=>{
        this.router.navigateByUrl('/login')
      })
  }

}
