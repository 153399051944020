export const environment = {
  production: false,
  title: 'Local Environment Heading',
  apiURL:'https://bookmymot.dev/partners/',
  endpoints :{
    jobs:'jobs',
    jobUpdate:'jobs/update',
    jobsToggleService:'jobs/toggle-service',

    bookings:'bookings',
    bookingsCount:'bookings-count',
    bookingsByDateFrame:'bookings/by-date',
    getBooking:'booking',
    cancelBooking:'booking/cancel',
    updateBookingDateTime:'booking/update/date-time',
    bookingNotifyCustomer:'booking/notify-customer',

    historyCount:'history-count',
    historyByDate:'history/by-date',

    calendar:'calendar',
    settings:'settings',
    search:'search',
    me:'me',
    saveMedia:'save-media',
    deleteMedia : 'delete-media',
    saveRepair:'repairs/save',
    deleteRepair:'repairs/delete',
    //Services
    getServices:'services',
    updateService:'services/update',
    //Mot
    updateMot:'mot/update',
    toggleMot:'mot/toggle'
  },

//firebase
  firebase : {

    //These are the testing settings
    apiKey: "AIzaSyDgfm_VmRWXoNC3sMiXcNv8cpUkpcciC-s",
    authDomain: "bookmymot-testing.firebaseapp.com",
    projectId: "bookmymot-testing",
    storageBucket: "bookmymot-testing.appspot.com",
    messagingSenderId: "559514415008",
    appId: "1:559514415008:web:b156539a760d740f387ec5"
  }

//endfirebase

};
