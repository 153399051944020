import { Injectable } from '@angular/core';
import {FireBaseService} from "../firebase/fire-base.service";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {EnabledResult, LocalNotifications, LocalNotificationSchema} from "@capacitor/local-notifications";
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private userId?:number

  private notifications = new BehaviorSubject<any>([])

  public notifications$:Observable<any[]> = this.notifications as Observable<Array<any>>

  constructor(private fireStore:FireBaseService) { }

  public setUser(userId:number) {
    this.userId = userId
     this.fireStore.getNotifications(userId).pipe(tap((data)=>{
      data.forEach((item:any, index:number)=>{
        if(item.status == 'new') {
          this.notify( {
            id: index,
            title: "New booking!",
            body: item.message,
          })
        }
      })
      this.notifications.next(data)
    })).subscribe()
  }

  public notify(notification:LocalNotificationSchema) {
    this.fireSwal(notification)
    LocalNotifications.areEnabled().then((response:EnabledResult)=>{
      if(!response){
        LocalNotifications.requestPermissions().then()
      }
    })

    LocalNotifications.schedule({notifications:[notification]}).then((d)=>{
      this.fireSwal(notification)
    })
  }

  private fireSwal({title, body}:{title:string, body:string}) {
    Swal.fire({
      title: title,
      text:body,
      toast:true,
      position:'top',
      timer:2000,
      showConfirmButton:false,
      icon:'success'
    })
  }

  public readNotifications():void {
    this.fireStore.readNotification(this.userId)
  }

  public clearNotifications():void {
    this.fireStore.clearNotifications(this.userId)
  }
}
